export const bodyParts = [
  { id: "1", bodyPart: "Head" },
  { id: "2", bodyPart: "Skin" },
  { id: "3", bodyPart: "Legs" },
  { id: "4", bodyPart: "Hands" },
  { id: "5", bodyPart: "Thigh" },
  { id: "6", bodyPart: "Feet" },
  { id: "7", bodyPart: "Thumb" },
  { id: "8", bodyPart: "Nails" },
  { id: "9", bodyPart: "Hairs" },
  { id: "10", bodyPart: "Back" },
  { id: "11", bodyPart: "Abdomen" },
  { id: "12", bodyPart: "Face" },
  { id: "13", bodyPart: "Hip" },
  { id: "14", bodyPart: "Arm" },
  { id: "15", bodyPart: "Shoulder" },
  { id: "16", bodyPart: "Waist" },
  { id: "17", bodyPart: "Neck" },
]