//custom login page
import LoginPage from "./Modules/Login/LoginPage";
import React from "react";
import { Admin, Resource, Layout, AppBar } from "react-admin";
import {
  QuestionCreate,
  QuestionEdit,
  QuestionList,
} from "./resources/QuestionnaireResource";
import {
  UsersList
} from "./resources/UsersResource";

import { createMuiTheme } from "@material-ui/core";
// import { HelpOutline } from '@material-ui/icons'
import { createBrowserHistory as createHistory } from "history";

// Firebase setup

import {
  FirebaseAuthProvider,
  FirebaseDataProvider
} from "react-admin-firebase";

import authProvider from "./authProvider";
import { BodyPartCreate, BodyPartEdit, BodyPartList } from "./resources/BodyPartResource";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKHsD-xLo-pd4-6SS5iyHxBI6APFN4Yw8",
  authDomain: "dr-alka-ai.firebaseapp.com",
  projectId: "dr-alka-ai",
  storageBucket: "dr-alka-ai.appspot.com",
  messagingSenderId: "995010854247",
  appId: "1:995010854247:web:952775eae149c7257583c2",
  measurementId: "G-YZEH359FLP"
};

const options = {};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProviderOrig = FirebaseAuthProvider(firebaseConfig);
// console.log('authProvider', authProvider)

console.log(authProvider);

//firebase setup end

const history = createHistory();

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#0D6690", contrastText: "#fff" },
    secondary: { main: "#0D6690", contrastText: "#fff" },
  },
});
function App() {
  return (
    <Admin
      loginPage={LoginPage}
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProviderOrig}
      layout={CustomLayout}
      title="Dr. Alkas AI Admin Panel"
      history={history}
    >
      <Resource
        name="Users"
        options={{ label: "Users" }}
        list={UsersList}
      // create={UsersCreate}
      // edit={UsersEdit}
      />
      <Resource
        name="questionnaire"
        options={{ label: "Questionniare" }}
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
      />
      {/* <Resource
        name="bodyPartData"
        options={{ label: "Body Parts" }}
        list={BodyPartList}
        create={BodyPartCreate}
        edit={BodyPartEdit}
      /> */}
    </Admin>
  );
}
const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;
const CustomAppBar = ({ ...props }) => (
  <AppBar {...props}>
    {/* <Typography style={{ flex: 1 }}>{props.title}</Typography>
    <IconButton onClick={() => history.push('/support')}>
      <HelpOutline style={{ fill: '#fff' }} />
    </IconButton> */}
  </AppBar>
);

export default App;

// write sum_pairs function. It has 2 arguments, first is an array of numbers, and second is number.
// Find the adjacent numbers, of which the total is the second argument.
// If the number already belongs to pair, it cannot be part of another pair
// Eg: sum_pairs([11, 3, 7, 3, 5, 5, 3, 7], 10);  //ans [[3, 7], [5, 5], [3, 7]]
// Important [7,3]  is not part of answer, because 7 is already part of first [3,7] pair
